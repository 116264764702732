import {
  LogicOperator,
  NodeArray,
  NodeObject,
} from "@/components/targetingpicker/utils/typedefs";

export function isNodeArray(obj: unknown): obj is NodeArray {
  return Array.isArray(obj) && obj.every(isNodeObject);
}

export function isNodeObject(obj: unknown): obj is NodeObject {
  return (
    typeof obj === "object" &&
    obj !== null &&
    !(obj instanceof Array) &&
    !(obj instanceof Date)
  );
}

export function isLogicOperator(obj: unknown): obj is LogicOperator {
  return typeof obj === "string" && ["and", "or"].includes(obj);
}
