import { isInteger } from "@/lib/rules/idsValidator";

export function isNonEmptyIntegerField(
  val: string | number | null
): string | true {
  if ((typeof val === "string" && val === "") || val === null) {
    return "The field cannot be empty";
  }
  return isIntegerOrEmpty(val);
}
export function isIntegerOrEmpty(value: string | number | null): string | true {
  if (
    value === null ||
    (typeof value === "string" && isInteger(value)) ||
    (typeof value === "string" && value === "") ||
    typeof value === "number"
  ) {
    return true;
  }
  return "Invalid value";
}

export function isWithinRange(
  value: number,
  min: number,
  max: number,
  size: number
): string | true {
  if (!isNaN(min) && value < min) {
    return `Must be bigger than ${min - 1}`;
  } else if (!isNaN(max) && value > max) {
    return `Must be lower than ${max + 1}`;
  } else if (!isNaN(size) && value.toString().length > size) {
    return `Must contain less than ${size + 1} digits`;
  }
  return true;
}
