export function trimPathIndex(path: string): string {
  const reg = new RegExp("\\[\\d+]$");
  return path.replace(reg, "");
}

export function getParentPath(path: string): string {
  const items = path.split(".");
  items.pop();
  return items.join(".");
}

export function getOperatorFromPath(path: string): string {
  const items = trimPathIndex(path).split(".");
  const operator = items.pop();
  if (typeof operator !== "string") {
    throw new Error("Could not extract operator");
  }
  return operator;
}

export function getPathIndex(path: string): number {
  const reg = new RegExp("\\[(\\d+)]$");
  const result = reg.exec(path);
  if (result !== null) {
    return Number(result[1]);
  }

  return 0;
}
