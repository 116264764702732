import { debounce } from "lodash";
import { computed, Ref, ref, watch } from "vue";

import { FilterEventPayload } from "@/api/typedefs";
import { Option } from "@/components/targetingpicker/typedefs";

interface UseFilterDropdownPayload {
  modelValue: string | number | null;
  options: Option[];
  onFilter?: (payload: FilterEventPayload) => Promise<void>;
  label?: string | null;
  required?: boolean;
}

export function useFilterDropdown(payload: UseFilterDropdownPayload) {
  const filterText = ref("");
  const isFilterLoading = ref(false);
  const errorMessage: Ref<null | string> = ref(null);

  const flattenedItems = computed(() => {
    const flattened: FlattenedItem[] = [];
    payload.options.forEach((item) => {
      if (item.id) {
        flattened.push({ type: "item", label: item.text, value: item.id });
      } else if (item.children) {
        flattened.push({ type: "group", label: item.text });
        item.children.forEach((child) => {
          flattened.push({
            type: "item",
            label: child.text,
            value: child.id,
            isChild: true,
          });
        });
      }
    });
    return flattened;
  });

  const label = computed((): string => {
    const selectedItem = flattenedItems.value.find(
      (item) => item.type === "item" && item.value === payload.modelValue
    );
    return selectedItem
      ? selectedItem.label
      : payload.label || "Select an option";
  });

  const applyFilter = debounce(async () => {
    const onSuccess = () => (isFilterLoading.value = false);
    if (payload.onFilter !== undefined) {
      isFilterLoading.value = true;
      try {
        // TODO: Consider handling filter abort
        await payload.onFilter({
          value: filterText.value,
          onSuccess: onSuccess,
          onError: onSuccess,
        });
      } catch {
        isFilterLoading.value = false;
      }
    }
  }, 500);

  watch([filterText], () => {
    applyFilter();
  });

  const onDropdownHide = () => {
    filterText.value = "";
  };

  const scrollTo = computed(() => {
    return flattenedItems.value.findIndex((value) => {
      return value.value === payload.modelValue;
    });
  });

  const validate = () => {
    if (payload.required && payload.modelValue === "") {
      errorMessage.value = "Field is required";
      return false;
    }
    return true;
  };

  const resetValidation = () => {
    errorMessage.value = null;
  };

  return {
    filterText,
    isFilterLoading,
    flattenedItems,
    label,
    onDropdownHide,
    scrollTo,
    validate,
    errorMessage,
    resetValidation,
  };
}

interface FlattenedItem {
  type: "item" | "group";
  label: string;
  value?: string | number | null;
  isChild?: boolean;
}
