import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = {
  key: 0,
  class: "row no-wrap q-pl-xs"
}
const _hoisted_3 = { class: "q-gutter-x-sm q-gutter-y-xs row items-center justify-center" }
const _hoisted_4 = { class: "text-bold q-py-xs text-center" }
const _hoisted_5 = {
  key: 1,
  class: "row q-gutter-x-sm"
}
const _hoisted_6 = {
  key: 0,
  class: "q-my-auto"
}
const _hoisted_7 = {
  key: 1,
  class: "q-mx-sm"
}

import { inject, toRefs } from "vue";

import BorderedContainer from "@/components/targetingpicker/BorderedContainer.vue";
import FilterDropdown from "@/components/targetingpicker/FilterDropdown.vue";
import { segmentsKey } from "@/components/targetingpicker/keys";
import { SegmentValueType } from "@/components/targetingpicker/typedefs";
import { useInputNode } from "@/components/targetingpicker/useInputNode";
import { NodeObject } from "@/components/targetingpicker/utils/typedefs";
import { isWithinRange } from "@/lib/rules/integerValidator";

import VariableInput from "./VariableInput.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InputNode',
  props: {
    path: {},
    index: {},
    modelValue: {},
    disable: { type: Boolean },
    readOnly: { type: Boolean }
  },
  emits: ["update:modelValue", "remove"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const { modelValue, path } = toRefs(props);

const segments = inject(segmentsKey);
if (segments === undefined) {
  throw new Error("Could not inject segments key");
}

const {
  updateValue,
  updateOperator,
  parentOperator,
  segment,
  options,
  operatorName,
  firstVariableValue,
  secondVariableValue,
  fromSegment,
  toSegment,
} = useInputNode({
  segments: segments,
  modelValue: modelValue,
  path: path,
});

const emit = __emit;

function onUpdateFirstValue(value: SegmentValueType) {
  emit("update:modelValue", updateValue(value, 0));
}

function onUpdateSecondValue(value: SegmentValueType) {
  emit("update:modelValue", updateValue(value, 1));
}

function onUpdateOperator(value: string) {
  emit("update:modelValue", updateOperator(value));
}

function onRemove() {
  emit("remove");
}

return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createBlock(BorderedContainer, {
    "hide-border": _unref(parentOperator) === 'or'
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_unref(segment))
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("label", _hoisted_4, _toDisplayString(_unref(segment).configuration.label), 1),
                _createElementVNode("div", {
                  class: _normalizeClass(["row items-center q-gutter-x-sm justify-center", {
                reverse: _unref(segment).configuration.matching_at_end,
              }])
                }, [
                  (_unref(segment).configuration.matching !== undefined)
                    ? (_openBlock(), _createBlock(FilterDropdown, {
                        key: 0,
                        options: _unref(options),
                        "model-value": _unref(operatorName),
                        "emit-value": "",
                        disable: _ctx.disable || _ctx.readOnly,
                        "onUpdate:modelValue": onUpdateOperator
                      }, null, 8, ["options", "model-value", "disable"]))
                    : _createCommentVNode("", true),
                  (
                  _unref(segment).configuration.type === 'bucket' &&
                  _unref(fromSegment) !== undefined &&
                  _unref(toSegment) !== undefined &&
                  _unref(firstVariableValue) !== undefined &&
                  _unref(secondVariableValue) !== undefined
                )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createVNode(VariableInput, {
                          segment: _unref(fromSegment),
                          "model-value": _unref(firstVariableValue),
                          "increase-display-value": 1,
                          disable: _ctx.disable,
                          "read-only": _ctx.readOnly,
                          "extra-rules": [
                      (val) =>
                        _unref(isWithinRange)(
                          Number(val),
                          NaN,
                          _unref(secondVariableValue) !== null ? Number(_unref(secondVariableValue)) + 1 : NaN,
                          NaN
                         ),
                    ],
                          "reactive-rules": true,
                          "node-value": _unref(modelValue),
                          "onUpdate:modelValue": onUpdateFirstValue
                        }, null, 8, ["segment", "model-value", "disable", "read-only", "extra-rules", "node-value"]),
                        _createVNode(VariableInput, {
                          segment: _unref(toSegment),
                          "model-value": _unref(secondVariableValue),
                          "increase-display-value": 1,
                          disable: _ctx.disable,
                          "read-only": _ctx.readOnly,
                          "node-value": _unref(modelValue),
                          "onUpdate:modelValue": onUpdateSecondValue
                        }, null, 8, ["segment", "model-value", "disable", "read-only", "node-value"])
                      ]))
                    : (
                  _unref(firstVariableValue) !== undefined && _unref(operatorName) !== 'never'
                )
                      ? (_openBlock(), _createBlock(VariableInput, {
                          key: 2,
                          segment: _unref(segment),
                          "model-value": _unref(firstVariableValue),
                          disable: _ctx.disable,
                          "read-only": _ctx.readOnly,
                          "node-value": _unref(modelValue),
                          "onUpdate:modelValue": onUpdateFirstValue
                        }, null, 8, ["segment", "model-value", "disable", "read-only", "node-value"]))
                      : _createCommentVNode("", true)
                ], 2)
              ]),
              (!_ctx.readOnly)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createVNode(_component_q_btn, {
                      flat: "",
                      size: "sm",
                      dense: "",
                      rounded: "",
                      class: "text-red",
                      disable: _ctx.disable,
                      onClick: onRemove
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_icon, { name: "close" })
                      ]),
                      _: 1
                    }, 8, ["disable"])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_7))
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["hide-border"]))
}
}

})