const SEPARATOR_COMMA = ",";

export const isInteger = (value: string) => /^\d+$/.test(value);

const hasWhiteSpace = (value: string) => /\s/g.test(value);

export function isStrCommaSeparatedListOfInts(value: unknown): true | string {
  if (value === null) {
    return true;
  }

  if (typeof value !== "string") {
    return "Must be a string";
  }

  if (value === "") return true;

  const items = value.split(SEPARATOR_COMMA);

  if (items.every((item) => isInteger(item))) {
    return true;
  }

  return "Must be a comma separated list of integers.";
}

export function isStrCommaSeparatedListOfStrings(
  value: unknown
): true | string {
  if (value === null) {
    return true;
  }

  if (typeof value !== "string") {
    return "Must be a string";
  }

  if (value === "") return true;

  const items = value.split(SEPARATOR_COMMA);

  if (
    items.every((item) => item.trim().length > 0 && !hasWhiteSpace(item.trim()))
  ) {
    return true;
  }

  return "Must be a comma separated list of strings.";
}
