import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { inject, toRefs } from "vue";

import { TargetSegment } from "@/api/segments/typedefs";
import { fetchSegmentValuesWithPath } from "@/api/segments/values";
import { ScrollEventPayload } from "@/api/typedefs";
import DateInput from "@/components/shared/DateInput.vue";
import FilterDropdown from "@/components/targetingpicker/FilterDropdown.vue";
import { dictionaryKey, productKey } from "@/components/targetingpicker/keys";
import { SegmentValueType } from "@/components/targetingpicker/typedefs";
import { useVariableInput } from "@/components/targetingpicker/useVariableInput";
import { NodeObject } from "@/components/targetingpicker/utils/typedefs";
import { usePaginatedSelect } from "@/composables/usePaginatedSelect";
import {
  isNonEmptyIntegerField,
  isWithinRange,
} from "@/lib/rules/integerValidator";
import {
  isNonEmptyString,
  isValidDate,
  isWithinCharacterLimit,
} from "@/lib/rules/stringValidatior";
import { ValidationRule } from "@/lib/rules/typedefs";


export default /*@__PURE__*/_defineComponent({
  __name: 'VariableInput',
  props: {
    segment: {},
    modelValue: {},
    increaseDisplayValue: {},
    disable: { type: Boolean },
    readOnly: { type: Boolean },
    extraRules: {},
    nodeValue: {}
  },
  emits: ["update:modelValue", "virtualScroll"],
  setup(__props: any, { emit: __emit }) {

_useCssVars(_ctx => ({
  "247e8d79": (_unref(placeholderLength))
}))

const props = __props;

const { modelValue, segment, increaseDisplayValue } = toRefs(props);

const emit = __emit;

const productId = inject(productKey);
if (productId === undefined) {
  throw new Error("Could not inject product line id");
}

const dictionary = inject(dictionaryKey);

const {
  options: fetchedValues,
  fetchOptions,
  fetchMoreOptions,
} = usePaginatedSelect({
  paginatedFetchMethod: (query, page, perPage) => {
    const segmentUrl = segment.value?.configuration.url;

    if (segmentUrl === undefined) {
      throw new Error("Segment configuration url is undefined");
    }

    return fetchSegmentValuesWithPath(
      segmentUrl,
      productId.value,
      query,
      page,
      perPage,
      segment.value?.configuration.url_requires_product_id
    );
  },
});

const {
  onVirtualScroll,
  onFilterValues,
  valueOptions,
  placeholderLength,
  segmentDictionary,
  updateValue,
  displayValue,
  primitiveValue,
  currentOptionLabel,
} = useVariableInput({
  segment: segment,
  fetchOptions: fetchOptions,
  fetchedValues: fetchedValues,
  fetchMoreOptions: fetchMoreOptions,
  modelValue: modelValue,
  dictionary: dictionary,
  increaseDisplayValue: increaseDisplayValue,
});

function onUpdateValue(value: string | number | null) {
  emit("update:modelValue", updateValue(value));
}

return (_ctx: any,_cache: any) => {
  const _component_q_input = _resolveComponent("q-input")!

  return (_unref(segment).configuration.type === 'unsupported')
    ? (_openBlock(), _createBlock(_component_q_input, {
        key: 0,
        "model-value": JSON.stringify(_ctx.nodeValue),
        dense: "",
        "hide-bottom-space": "",
        rounded: "",
        "stack-label": "",
        readonly: true
      }, null, 8, ["model-value"]))
    : (
      _unref(segment).configuration.type === 'text' &&
      _unref(segment).configuration.handler_type === 'calendar' &&
      typeof _unref(primitiveValue) !== 'number'
    )
      ? (_openBlock(), _createBlock(DateInput, {
          key: 1,
          dense: "",
          "hide-bottom-space": "",
          "stack-label": "",
          "model-value": _unref(primitiveValue),
          rules: [_unref(isValidDate), ...(_ctx.extraRules ? _ctx.extraRules : [])],
          "always-emit": true,
          disable: _ctx.disable || _ctx.readOnly,
          "onUpdate:modelValue": onUpdateValue
        }, null, 8, ["model-value", "rules", "disable"]))
      : (_unref(segment).configuration.type === 'text')
        ? (_openBlock(), _createBlock(_component_q_input, {
            key: 2,
            "model-value": _unref(primitiveValue),
            dense: "",
            rules: [
        _unref(isNonEmptyString),
        (val) =>
          _unref(isWithinCharacterLimit)(val, Number(_unref(segment).configuration.size)),
        ...(_ctx.extraRules ? _ctx.extraRules : []),
      ],
            "hide-bottom-space": "",
            rounded: "",
            "stack-label": "",
            placeholder: _unref(segment).configuration.placeholder,
            disable: _ctx.disable,
            readonly: _ctx.readOnly,
            "onUpdate:modelValue": onUpdateValue
          }, null, 8, ["model-value", "rules", "placeholder", "disable", "readonly"]))
        : (
      _unref(segment).configuration.type === 'number' &&
      _unref(segment).configuration.handler_type === 'input_limiter'
    )
          ? (_openBlock(), _createBlock(_component_q_input, {
              key: 3,
              "model-value": _unref(displayValue),
              dense: "",
              type: "number",
              rules: [
        _unref(isNonEmptyIntegerField),
        (val) =>
          _unref(isWithinRange)(
            val,
            Number(_unref(segment).configuration.limiter_min),
            Number(_unref(segment).configuration.limiter_max),
            Number(_unref(segment).configuration.size)
          ),
        ...(_ctx.extraRules ? _ctx.extraRules : []),
      ],
              "hide-bottom-space": "",
              min: _unref(segment).configuration.limiter_min,
              max: _unref(segment).configuration.limiter_max,
              placeholder: _unref(segment).configuration.placeholder,
              "input-class": "fit-placeholder",
              disable: _ctx.disable,
              readonly: _ctx.readOnly,
              "onUpdate:modelValue": onUpdateValue
            }, null, 8, ["model-value", "rules", "min", "max", "placeholder", "disable", "readonly"]))
          : (_openBlock(), _createBlock(FilterDropdown, {
              key: 4,
              options: _unref(valueOptions),
              "model-value": _unref(primitiveValue),
              "segment-dictionary": _unref(segmentDictionary),
              "emit-value": "",
              "on-filter": _unref(onFilterValues),
              "disable-filter": _unref(segment).configuration.handler_type === 'static_list',
              required: "",
              placeholder: _unref(segment).configuration.placeholder,
              disable: _ctx.disable || _ctx.readOnly,
              label: _unref(currentOptionLabel),
              "onUpdate:modelValue": onUpdateValue,
              onVirtualScroll: _unref(onVirtualScroll)
            }, null, 8, ["options", "model-value", "segment-dictionary", "on-filter", "disable-filter", "placeholder", "disable", "label", "onVirtualScroll"]))
}
}

})