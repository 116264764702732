export function isNonWhiteCharOnlyString(val: string | null): string | true {
  if (val === null) {
    return true;
  }
  return (
    val.trim().length > 0 ||
    "The field must contain at least one non-whitespace character"
  );
}

export function isNonEmptyString(val: string | null): string | true {
  if (val === null) {
    return "The field cannot be empty";
  }
  return (val && val.length > 0) || "The field cannot be empty";
}

export function isWithinCharacterLimit(value: string, size: number) {
  if (!isNaN(size) && value.length > size) {
    return `Must contain less than ${size + 1} characters`;
  }
  return true;
}

export function isValidDate(value: string | null): string | true {
  if (value === null) {
    return "The field cannot be empty";
  }

  const regex = /^\d{4}-\d{2}-\d{2}$/;

  if (!regex.test(value)) return "Must be a valid date";

  const [year, month, day] = value.split("-").map(Number);

  const date = new Date(year, month - 1, day);
  const isValid =
    date.getFullYear() === year &&
    date.getMonth() === month - 1 &&
    date.getDate() === day;
  if (!isValid) {
    return "Must be a valid date";
  }

  return true;
}
