import script from "./TargetModal.vue?vue&type=script&setup=true&lang=ts"
export * from "./TargetModal.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard,QSpinner});
