import { computed, ref, Ref, watch } from "vue";

import { isDateStringValidFormat } from "@/lib/dateTime";
import { ValidationRule } from "@/lib/rules/typedefs";

interface DateInputOptions {
  modelValue: Ref<string | null>;
  onDateChange: (arg: string | null) => void;
  extraRules?: Ref<ValidationRule<string | null>[]>;
  alwaysEmit?: Ref<boolean>;
}

const DEFAULT_RULES: ValidationRule<string | null>[] = [
  (val: string | null) =>
    val === null ||
    val === "" ||
    isDateStringValidFormat(val) ||
    "This is not a valid date",
];

export function useDateInput({
  modelValue,
  onDateChange,
  extraRules,
  alwaysEmit,
}: DateInputOptions) {
  const date: Ref<string | null> = ref(null);

  watch(
    [modelValue],
    () => {
      if (
        modelValue.value === null ||
        !isDateStringValidFormat(modelValue.value)
      ) {
        return;
      }
      date.value = modelValue.value;
    },
    { immediate: true }
  );

  watch([date], () => {
    if (alwaysEmit?.value) {
      onDateChange(date.value);
      return;
    }
    // This behavior breaks reactivity, which is needed for validation rules to work properly.
    if (date.value !== null && isDateStringValidFormat(date.value)) {
      onDateChange(date.value);
    } else {
      onDateChange(null);
    }
  });

  const rules = computed(() => [
    ...DEFAULT_RULES,
    ...(extraRules?.value ?? []),
  ]);

  return {
    rules,
    date,
  };
}
