import { computed, Ref, ref } from "vue";

import { TargetDetail, TargetType } from "@/api/target/typedefs";
import { usePermissions } from "@/composables/usePermissions";
import { getProductLine } from "@/lib/productLine";

export function useTargetsForm(
  initialValue: TargetDetail,
  productLineId: Ref<number>,
  targetType: Ref<TargetType>
) {
  const { hasInAppPermission, hasPushMessagePermission } = usePermissions();

  const productLine = computed(() => getProductLine(productLineId.value));

  const hasChangePermission = computed(() => {
    if (targetType.value === "pushnotifications") {
      return hasPushMessagePermission(
        "change_messagetarget",
        productLine.value
      );
    } else {
      return hasInAppPermission("change_inapptarget", productLine.value);
    }
  });

  const value = ref<TargetDetail>(initialValue);

  const timezones = [
    {
      label: "UTC-12:00",
      value: "utc_-12",
    },
    {
      label: "UTC-11:00",
      value: "utc_-11",
    },
    {
      label: "UTC-10:00",
      value: "utc_-10",
    },
    {
      label: "UTC-09:00",
      value: "utc_-9",
    },
    {
      label: "UTC-08:00",
      value: "utc_-8",
    },
    {
      label: "UTC-07:00",
      value: "utc_-7",
    },
    {
      label: "UTC-06:00",
      value: "utc_-6",
    },
    {
      label: "UTC-05:00",
      value: "utc_-5",
    },
    {
      label: "UTC-04:00",
      value: "utc_-4",
    },
    {
      label: "UTC-03:00",
      value: "utc_-3",
    },
    {
      label: "UTC-02:00",
      value: "utc_-2",
    },
    {
      label: "UTC-01:00",
      value: "utc_-1",
    },
    {
      label: "UTC",
      value: "utc_0",
    },
    {
      label: "UTC+01:00",
      value: "utc_1",
    },
    {
      label: "UTC+02:00",
      value: "utc_2",
    },
    {
      label: "UTC+03:00",
      value: "utc_3",
    },
    {
      label: "UTC+04:00",
      value: "utc_4",
    },
    {
      label: "UTC+05:00",
      value: "utc_5",
    },
    {
      label: "UTC+06:00",
      value: "utc_6",
    },
    {
      label: "UTC+07:00",
      value: "utc_7",
    },
    {
      label: "UTC+08:00",
      value: "utc_8",
    },
    {
      label: "UTC+09:00",
      value: "utc_9",
    },
    {
      label: "UTC+10:00",
      value: "utc_10",
    },
    {
      label: "UTC+11:00",
      value: "utc_11",
    },
    {
      label: "UTC+12:00",
      value: "utc_12",
    },
  ];

  return {
    value,
    timezones,
    hasChangePermission,
  };
}
