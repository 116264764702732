import { compact, get, set, unset, update } from "lodash";

import {
  isNodeArray,
  isNodeObject,
} from "@/components/targetingpicker/utils/guards";
import {
  getOperatorFromPath,
  getParentPath,
  getPathIndex,
  trimPathIndex,
} from "@/components/targetingpicker/utils/path";
import {
  NodeArray,
  NodeObject,
} from "@/components/targetingpicker/utils/typedefs";

export function wrapInOperator(
  objects: NodeArray,
  operator: string
): NodeObject {
  return {
    [operator]: objects,
  };
}

export function appendObjects(
  newValue: NodeObject,
  from: string,
  to: string,
  insertAt: number,
  valuesToMove: NodeObject[]
): NodeObject {
  const targetValues = get(newValue, to);
  if (!isNodeArray(targetValues)) {
    throw new Error("Unexpected type");
  }
  // If we are moving appending more than one object, we want to retain their operator
  if (valuesToMove.length > 1) {
    const operator = getOperatorFromPath(from);
    targetValues.splice(insertAt, 0, wrapInOperator(valuesToMove, operator));
  } else if (to === "") {
    // Moving object to root
    return valuesToMove[0];
  } else {
    targetValues.splice(insertAt, 0, valuesToMove[0]);
  }
  // Assuming that `to` won't be root
  return set(newValue, to, targetValues);
}

export function moveUp(
  tree: NodeObject,
  fromPath: string,
  index: number
): NodeObject {
  const toPath = getParentPath(fromPath);

  let start;
  let end;
  let insertBefore;

  const fromArray = get(tree, fromPath);
  if (!isNodeArray(fromArray)) {
    throw new Error("Unexpected type");
  }

  if (index === 0) {
    start = index;
    end = 1;
    insertBefore = true;
  } else if (index === fromArray.length - 2) {
    start = fromArray.length - 1;
    end = 1;
    insertBefore = false;
  } else {
    start = 0;
    end = index + 1;
    insertBefore = true;
  }

  let insertAt;
  if (insertBefore) {
    insertAt = getPathIndex(toPath);
  } else {
    insertAt = getPathIndex(toPath) + 1;
  }
  const to = trimPathIndex(toPath);

  const valuesToMove = fromArray.splice(start, end);

  // If there is one element of a given node, then we can get rid of its operator.
  // If there is none, then clean its leaf.
  if (fromArray.length === 1) {
    tree = set(tree, toPath, fromArray[0]);
  } else if (fromArray.length === 0) {
    unset(tree, toPath);
    tree = update(tree, trimPathIndex(toPath), compact);
  }

  return appendObjects(tree, fromPath, to, insertAt, valuesToMove);
}

export function moveDown(
  tree: NodeObject,
  fromPath: string,
  targetOperator: string,
  index: number
): NodeObject {
  const fromArray = get(tree, fromPath);
  if (!isNodeArray(fromArray)) {
    throw new Error("Unexpected type");
  }

  // Check if there's targetOperator on the left or right of the logic button
  const leftMatch =
    index >= 0 && getOperator(fromArray[index]) === targetOperator;
  const rightMatch =
    index < fromArray.length - 1 &&
    getOperator(fromArray[index + 1]) === targetOperator;

  if (leftMatch || rightMatch) {
    let valuesToMove = leftMatch
      ? fromArray.splice(index + 1, 1)
      : fromArray.splice(index, 1);

    // After doing splice, the target array is at index position.
    const toPath = `${fromPath}[${index}].${targetOperator}`;
    const targetArray = get(tree, toPath);
    if (!isNodeArray(targetArray)) {
      throw new Error("Unexpected type");
    }
    const insertAt = leftMatch ? targetArray.length : 0;

    // We're moving a list of the same type, so we need to unwrap it.
    if (
      getOperator(valuesToMove[0]) === targetOperator &&
      isNodeArray(valuesToMove[0]["or"])
    ) {
      valuesToMove = valuesToMove[0]["or"];
    }

    targetArray.splice(insertAt, 0, ...valuesToMove);
  } else {
    // If there are no matching neighbours, we just needs to wrap the values around the button.
    const valuesToMove = fromArray.splice(index, 2);
    fromArray.splice(index, 0, wrapInOperator(valuesToMove, targetOperator));
  }

  // If there's only one element left, let's unwrap it
  if (fromArray.length === 1) {
    // Lodash doesn't understand root path
    if (getParentPath(fromPath) === "") {
      tree = fromArray[0];
    } else {
      tree = set(tree, getParentPath(fromPath), fromArray[0]);
    }
  }

  return tree;
}

export function getOperator(obj: NodeObject): string {
  if (!isNodeObject(obj)) {
    throw new Error("Unexpected type");
  }

  if (Object.keys(obj).length === 1) {
    return Object.keys(obj)[0];
  }

  throw new Error("Unexpected number of keys");
}

export function getOperatorValue(obj: NodeObject): NodeArray {
  if (Object.values(obj).length === 1) {
    const value = Object.values(obj)[0];
    if (isNodeArray(value)) {
      return value;
    }
  }

  throw new Error("Unexpected type");
}

export function wrapTree(tree: NodeObject, operator: string): NodeObject {
  return wrapInOperator([tree], operator);
}

export function wrapTreePath(path: string, operator: string): string {
  const pathWords = path.split(".");
  pathWords.splice(0, 0, `${operator}[0]`);
  return pathWords.join(".");
}

export function isEmptyTree(tree: NodeObject) {
  return Object.keys(tree).length === 0;
}
