import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, unref as _unref, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-center q-gutter-sm" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "inline-block"
}
const _hoisted_4 = { key: 2 }
const _hoisted_5 = {
  key: 3,
  class: "q-pa-sm block"
}

import { computed } from "vue";

import BorderedContainer from "@/components/targetingpicker/BorderedContainer.vue";
import InputNode from "@/components/targetingpicker/InputNode.vue";
import {
  isLogicOperator,
  isNodeArray,
  isNodeObject,
} from "@/components/targetingpicker/utils/guards";
import { uniqueKey } from "@/components/targetingpicker/utils/key";
import {
  getOperator,
  getOperatorValue,
} from "@/components/targetingpicker/utils/tree";
import {
  NodeArray,
  NodeObject,
  ToggleOptions,
} from "@/components/targetingpicker/utils/typedefs";


export default /*@__PURE__*/_defineComponent({
  __name: 'Node',
  props: {
    parentPath: {},
    operator: {},
    nodeValue: {},
    disable: { type: Boolean },
    readOnly: { type: Boolean }
  },
  emits: ["toggle", "update-value", "remove"],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;

const props = __props;

const path = computed(() =>
  props.parentPath ? `${props.parentPath}.${props.operator}` : props.operator
);

function toggle(index: number) {
  emit("toggle", {
    path: path.value,
    btnIndex: index,
  });
}

function onUpdateValue(path: string, value: NodeObject) {
  emit("update-value", path, value);
}

function onRemove(path: string) {
  emit("remove", path);
}

return (_ctx: any,_cache: any) => {
  const _component_node = _resolveComponent("node", true)!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createBlock(BorderedContainer, {
    "hide-border": _ctx.operator !== 'or'
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.nodeValue, (value, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: _unref(uniqueKey)(value)
          }, [
            value
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (_unref(isNodeObject)(value) && _unref(isLogicOperator)(_unref(getOperator)(value)))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createVNode(_component_node, {
                          "parent-path": path.value + `[${index}]`,
                          operator: _unref(getOperator)(value),
                          "node-value": _unref(getOperatorValue)(value),
                          disable: _ctx.disable,
                          "read-only": _ctx.readOnly,
                          onToggle: _cache[0] || (_cache[0] = (options) => emit('toggle', options)),
                          onUpdateValue: onUpdateValue,
                          onRemove: onRemove
                        }, null, 8, ["parent-path", "operator", "node-value", "disable", "read-only"])
                      ]))
                    : (!_unref(isNodeArray)(value))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                          _createVNode(InputNode, {
                            path: path.value + `[${index}]`,
                            index: index,
                            "model-value": value,
                            disable: _ctx.disable,
                            "read-only": _ctx.readOnly,
                            "onUpdate:modelValue": (v) => onUpdateValue(path.value + `[${index}]`, v),
                            onRemove: ($event: any) => (onRemove(path.value + `[${index}]`))
                          }, null, 8, ["path", "index", "model-value", "disable", "read-only", "onUpdate:modelValue", "onRemove"])
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[1] || (_cache[1] = [
                          _createElementVNode("b", { class: "text-red wrapped q-pa-sm inline-block" }, "Invalid syntax", -1)
                        ]))),
                  (index !== props.nodeValue.length - 1)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createVNode(_component_q_btn, {
                          color: "secondary",
                          outline: "",
                          padding: "xs",
                          rounded: "",
                          size: "xs",
                          disable: _ctx.disable || _ctx.readOnly,
                          icon: "autorenew",
                          label: _ctx.operator,
                          onClick: ($event: any) => (toggle(index))
                        }, null, 8, ["disable", "label", "onClick"])
                      ]))
                    : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["hide-border"]))
}
}

})