import useQuasar from 'quasar/src/composables/use-quasar.js';;
import type { DialogChainObject } from "quasar";

import TargetModal from "@/components/shared/target/dialog/TargetModal.vue";
import { TargetFormSuccessResponse } from "@/components/shared/target/dialog/useTargetModal";

export interface UseOpenTargetModal {
  openTargetModal: (payload: {
    props: unknown;
    onOk: (data: {
      payload: TargetFormSuccessResponse;
      event: string;
    }) => unknown;
  }) => void;
}

export function useOpenTargetModal(): UseOpenTargetModal {
  const $q = useQuasar();

  const openTargetModal = (payload: {
    props: unknown;
    onOk: (data: {
      payload: TargetFormSuccessResponse;
      event: string;
    }) => unknown;
  }) => {
    let dialog = $q.dialog({
      component: TargetModal,
      componentProps: payload.props,
    });
    const onOk = async (data: {
      payload: TargetFormSuccessResponse | DialogChainObject;
      event: string;
    }) => {
      if (data.event === "clone") {
        dialog = data.payload as DialogChainObject;
        dialog.onOk(payload.onOk);
      } else {
        payload.onOk(
          data as {
            payload: TargetFormSuccessResponse;
            event: string;
          }
        );
      }
    };

    dialog.onOk(onOk);
  };

  return {
    openTargetModal,
  };
}
