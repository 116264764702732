
import QInput from 'quasar/src/components/input/QInput.js';;
import { defineComponent, PropType, Ref, ref, toRef } from "vue";

import { useDateInput } from "./useDateInput";
import { useInputProps } from "./useInputProps";

export default defineComponent({
  props: {
    ...useInputProps(),
    modelValue: {
      type: null as unknown as PropType<string | null>,
      required: true,
      validator: (v: unknown) => typeof v === "string" || v === null,
    },
    alwaysEmit: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  emits: {
    "update:modelValue": (v: string | null) =>
      typeof v === "string" || v === null,
  },
  setup(props, { emit }) {
    const inputRef: Ref<null | QInput> = ref(null);
    return {
      ...useDateInput({
        modelValue: toRef(props, "modelValue"),
        onDateChange: (value) => {
          inputRef.value?.validate(value);
          emit("update:modelValue", value);
        },
        extraRules: toRef(props, "extraRules"),
        alwaysEmit: toRef(props, "alwaysEmit"),
      }),
      inputRef: inputRef,
    };
  },
});
