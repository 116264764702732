import {
  NodeArray,
  NodeObject,
} from "@/components/targetingpicker/utils/typedefs";

export const idSymbol = Symbol("uniqueId");

export function uniqueKey(value: NodeArray | NodeObject): string {
  const obj = value as (NodeArray | NodeObject) & { [idSymbol]?: string };

  if (!Object.getOwnPropertySymbols(obj).includes(idSymbol)) {
    Object.defineProperty(obj, idSymbol, {
      value: Math.random().toString(36).slice(2, 11),
      enumerable: true,
      writable: false,
      configurable: true,
    });
  }

  return Reflect.get(obj, idSymbol) as string;
}
