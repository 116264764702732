import camelcaseKeys from "camelcase-keys";

import { SegmentValue, SegmentValueGuard } from "@/api/segments/typedefs";
import { httpClient, throwIfBadResponse } from "@/lib/http";
import { cast } from "@/lib/typing";

export function convertSegmentValue(data: unknown): SegmentValue {
  const segmentData = data as {
    id?: unknown;
    text?: unknown;
  };

  if (typeof segmentData !== "object" || segmentData.text === undefined) {
    throw new Error(`Invalid segment data type to convert - ${data}`);
  }

  // TODO(PNS-1738): Drop when BE will start sending camelCase.
  const camelCasedData = camelcaseKeys({
    id: segmentData["id"],
    name: segmentData["text"],
  });

  return cast(SegmentValueGuard, camelCasedData);
}

function convertSegmentValues(data: unknown): SegmentValue[] {
  const segmentValueData = data as {
    matches?: unknown;
  };

  if (!Array.isArray(segmentValueData?.["matches"])) {
    throw new Error("Failed to convert matches.");
  }
  return segmentValueData["matches"].map((item) => convertSegmentValue(item));
}

export async function fetchSegmentValues(
  segmentName: string,
  productId: number,
  query: string,
  page: number,
  perPage: number
) {
  return fetchSegmentValuesWithPath(
    `/api/${segmentName}-list`,
    productId,
    query,
    page,
    perPage,
    false
  );
}

export async function fetchSegmentValuesWithPath(
  path: string,
  productId: number,
  query: string,
  page: number,
  perPage: number,
  urlRequiresProductID: undefined | boolean
) {
  const queryParams = {
    q: query,
    per_page: String(perPage),
    page: String(page),
  };

  const paramsStr = new URLSearchParams(queryParams).toString();
  path = path.replace(/\/+$/g, ""); // trim `/` characters at the end
  let fullPath;
  if (urlRequiresProductID) {
    fullPath = `${path}/${productId}?${paramsStr}`;
  } else {
    fullPath = `${path}?${paramsStr}`;
  }
  const response = await httpClient.get(fullPath);

  await throwIfBadResponse(response);

  const result = await response.json();
  return convertSegmentValues(result);
}
