import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row items-center no-wrap" }
const _hoisted_2 = { class: "col-auto" }
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["textContent"]
const _hoisted_5 = {
  key: 1,
  class: "row items-center no-wrap"
}
const _hoisted_6 = { class: "col-auto" }

import copyToClipboard from 'quasar/src/utils/copy-to-clipboard.js';import useQuasar from 'quasar/src/composables/use-quasar.js';;
import {
  bigquery as dialectBigquery,
  formatDialect as formatSQL,
} from "sql-formatter";
import { computed, ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'QueryPreview',
  props: {
    modelValue: {},
    showHeader: { type: Boolean, default: true }
  },
  setup(__props: any) {

const props = __props;

const showFormatted = ref(false);
const formattedQuery = computed(() =>
  formatSQL(props.modelValue, {
    keywordCase: "upper",
    dialect: dialectBigquery,
  })
);
const quasar = useQuasar();

const queryToClipboard = () => {
  copyToClipboard(
    showFormatted.value ? formattedQuery.value : props.modelValue
  ).then(() => quasar.notify("Query string copied to clipboard"));
};

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_toggle = _resolveComponent("q-toggle")!
  const _component_q_card_section = _resolveComponent("q-card-section")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showHeader)
      ? (_openBlock(), _createBlock(_component_q_card_section, {
          key: 0,
          class: "q-pb-none"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _cache[2] || (_cache[2] = _createElementVNode("div", { class: "col" }, [
                _createElementVNode("div", { class: "text-h6" }, "Query")
              ], -1)),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_q_btn, {
                  label: "copy",
                  flat: "",
                  icon: "content_copy",
                  onClick: queryToClipboard
                }),
                _createVNode(_component_q_toggle, {
                  modelValue: showFormatted.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((showFormatted).value = $event)),
                  label: "Format",
                  class: "text-uppercase text-weight-medium"
                }, null, 8, ["modelValue"])
              ])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_q_card_section, null, {
      default: _withCtx(() => [
        (showFormatted.value)
          ? (_openBlock(), _createElementBlock("pre", {
              key: 0,
              textContent: _toDisplayString(formattedQuery.value)
            }, null, 8, _hoisted_3))
          : (_openBlock(), _createElementBlock("pre", {
              key: 1,
              textContent: _toDisplayString(props.modelValue)
            }, null, 8, _hoisted_4))
      ]),
      _: 1
    }),
    (!_ctx.showHeader)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "col" }, null, -1)),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_q_btn, {
              label: "copy",
              flat: "",
              icon: "content_copy",
              onClick: queryToClipboard
            }),
            _createVNode(_component_q_toggle, {
              modelValue: showFormatted.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((showFormatted).value = $event)),
              label: "Format",
              class: "text-uppercase text-weight-medium"
            }, null, 8, ["modelValue"])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})