import useQuasar from 'quasar/src/composables/use-quasar.js';;
import type { DialogChainObject } from "quasar";
import { ref } from "vue";

import { createTarget } from "@/api/target/create";
import { TargetType } from "@/api/target/typedefs";
import { updateTarget } from "@/api/target/update";
import TargetModal from "@/components/shared/target/dialog/TargetModal.vue";
import { useTargetingData } from "@/composables/useTargetingData";
import { ReadonlyRef } from "@/lib/typing";

export interface TargetFormSuccessResponse {
  // TODO: Consider changing backend to return a full response
  // TODO: (otherwise, you'll have to make second request to get changed data)
  targetId: string;
}

export interface TargetFormErrorResponse {
  message: string;
}

export interface UseTargetFormPayload {
  productId: ReadonlyRef<number>;
  productLineId: ReadonlyRef<number>;
  targetType: ReadonlyRef<TargetType>;
  targetId: ReadonlyRef<number | undefined> | undefined;
  onSuccess: (payload: TargetFormSuccessResponse) => unknown;
  onError: (payload: TargetFormErrorResponse) => unknown;
  isClone: ReadonlyRef<boolean | undefined> | undefined;
  onClone: (payload: DialogChainObject) => unknown;
}

export function useTargetModal(payload: UseTargetFormPayload) {
  const isSubmitting = ref(false);
  const $q = useQuasar();
  const { isLoading, targetValue, fetchData } = useTargetingData({
    productLineId: payload.productLineId,
    targetType: payload.targetType,
    targetId: payload.targetId,
    onError: payload.onError,
  });

  function onClone() {
    const newDialog = $q.dialog({
      component: TargetModal,
      componentProps: {
        productId: payload.productId.value,
        targetType: payload.targetType.value,
        productLineId: payload.productLineId.value,
        targetId: payload.targetId?.value,
        isClone: true,
      },
    });
    payload.onClone(newDialog);
  }

  async function onSubmit(): Promise<void> {
    isSubmitting.value = true;
    try {
      let targetId;
      if (payload.targetId?.value === undefined || payload.isClone?.value) {
        const response = await createTarget(
          payload.targetType.value,
          payload.productLineId.value,
          targetValue.value
        );
        targetId = response.targetId;
      } else {
        const response = await updateTarget(
          payload.targetType.value,
          payload.productLineId.value,
          payload.targetId.value,
          targetValue.value
        );
        targetId = response.targetId;
      }
      await payload.onSuccess({
        targetId: targetId,
      });
    } catch (error: unknown) {
      await payload.onError({
        message: String(error),
      });
    } finally {
      isSubmitting.value = false;
    }
  }

  return {
    isSubmitting,
    isLoading,
    targetValue,
    fetchData,
    onSubmit,
    onClone,
  };
}
