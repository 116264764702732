import * as t from "io-ts";

export const InputOperatorTypeGuard = t.keyof({
  boolean_type: null,
  tag_boolean_type: null,
  date_type: null,
  numeric_type: null,
  list_type: null,
  list_type_repeated: null,
  text_type: null,
  operator_type: null,
  temporal_type: null,
  per_frequency: null,
});

export type InputOperatorType = t.TypeOf<typeof InputOperatorTypeGuard>;

export const TargetSegmentGuard = t.type({
  name: t.string,
  segmentType: t.string,
  order: t.number,
  isRuntime: t.boolean,
  configuration: t.intersection([
    t.type({
      label: t.string,
    }),
    t.partial({
      matching: InputOperatorTypeGuard,
      url: t.string,
      url_requires_product_id: t.boolean,
      type: t.string,
      handler_type: t.string,
      options: t.array(t.string),
      placeholder: t.string,
      limiter_min: t.union([t.string, t.number]),
      limiter_max: t.union([t.string, t.number]),
      size: t.union([t.string, t.number]),
      matching_at_end: t.boolean,
      custom_backend_name: t.string,
      to_type: t.string,
      from_type: t.string,
      to_placeholder: t.string,
      from_placeholder: t.string,
    }),
  ]),
});
export type TargetSegment = t.TypeOf<typeof TargetSegmentGuard>;

export const SegmentValueGuard = t.type({
  id: t.union([t.string, t.number]),
  name: t.string,
});

export type SegmentValue = t.TypeOf<typeof SegmentValueGuard>;
