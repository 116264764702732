import camelcaseKeys from "camelcase-keys";

import {
  TargetDetail,
  TargetDetailGuard,
  TargetType,
} from "@/api/target/typedefs";
import { httpClient, throwIfBadResponse } from "@/lib/http";
import { cast, TypeCastError } from "@/lib/typing";

export async function fetchTarget(
  targetType: TargetType,
  productLineId: number,
  targetId: number
): Promise<TargetDetail> {
  const path = `/${targetType}/target-json/${productLineId}/${targetId}`;
  const response = await httpClient.get(path);

  await throwIfBadResponse(response);

  const data = await response.json();

  return convertTarget(data);
}

export function convertTarget(data: unknown): TargetDetail {
  if (data === null || typeof data !== "object") {
    throw new TypeCastError("data must be an object");
  }

  const responseData: Record<string, unknown> = camelcaseKeys(data);
  return cast(TargetDetailGuard, responseData);
}
