const metadataSymbol = Symbol("metadata");

// MetaValue is used as a workaround for select fields that lose the `label` value once their component is re-rendered.
// This allows to append extra data such as `label` for object that acts like a String or Number.
// It would be best to eliminate component re-rendering, but I haven't managed to do that.
// It is an unforeseen consequence of nested structure of components.
export class MetaValue<T extends string | number> {
  private value: T;
  private [metadataSymbol]: Record<string, unknown>;

  constructor(value: T, metadata: Record<string, unknown> = {}) {
    this.value = value;
    this[metadataSymbol] = metadata;
  }

  getMetadata(key: string): unknown {
    return this[metadataSymbol][key];
  }

  setMetadata(key: string, value: unknown): void {
    this[metadataSymbol][key] = value;
  }

  toString(): string {
    return String(this.value);
  }

  toNumber(): number {
    return Number(this.value);
  }

  valueOf(): T {
    return this.value;
  }

  toJSON(): T {
    return this.value;
  }

  [Symbol.toPrimitive](hint: "string" | "number" | "default"): string | number {
    if (hint === "number") return this.toNumber();
    if (hint === "string") return this.toString();
    return this.value;
  }

  static isMetaValue(value: unknown): value is MetaValue<string | number> {
    return value instanceof MetaValue;
  }
}
