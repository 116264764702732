import { cloneDeep } from "lodash";
import { computed, ref, Ref, watch } from "vue";

import { TargetSegment } from "@/api/segments/typedefs";
import { FilterEventPayload } from "@/api/typedefs";
import { Option } from "@/components/targetingpicker/typedefs";
import {
  getDefaultInputOperatorForSegment,
  getSegmentByName,
} from "@/components/targetingpicker/utils/segments";
import { NodeObject } from "@/components/targetingpicker/utils/typedefs";

export function useSegmentPicker(segments: Ref<TargetSegment[]>) {
  const dropdownItems = ref<Option[]>([]);
  const allItems = computed(() => {
    const sortedData = [...segments.value].sort((a, b) => a.order - b.order);

    const result: { [key: string]: Option } = {};

    sortedData.forEach((item) => {
      if (item.segmentType === "") {
        result[item.name] = {
          id: item.name,
          text: item.configuration.label,
        };
      } else {
        if (!result[item.segmentType]) {
          result[item.segmentType] = {
            text: item.segmentType,
            children: [],
          };
        }
        result[item.segmentType].children?.push({
          id: item.name,
          text: item.configuration?.label,
        });
      }
    });

    return Object.values(result);
  });

  watch([allItems], () => (dropdownItems.value = cloneDeep(allItems.value)), {
    immediate: true,
  });

  const onFilter = async (payload: FilterEventPayload) => {
    try {
      let result = cloneDeep([...allItems.value]);
      result = result.filter((item) => {
        if (item.children) {
          item.children = item.children.filter((item) =>
            item.text.toLowerCase().includes(payload.value.toLowerCase())
          );
          return item.children.length > 0;
        }
        return item.text.toLowerCase().includes(payload.value.toLowerCase());
      });
      dropdownItems.value = result;
    } finally {
      payload.onSuccess();
    }
  };

  const makeNode = (rawSegmentName: string): NodeObject => {
    const selectedSegment: TargetSegment = getSegmentByName(
      segments.value,
      rawSegmentName
    );

    let segmentName = rawSegmentName;

    if (selectedSegment.configuration.custom_backend_name !== undefined) {
      segmentName = selectedSegment.configuration.custom_backend_name;
    }

    let value: string | null = "";
    if (
      selectedSegment.configuration.type === "number" ||
      selectedSegment.configuration.type === "bucket"
    ) {
      value = null;
    }

    if (selectedSegment.configuration.type === "only_matching") {
      return {
        [getDefaultInputOperatorForSegment(selectedSegment)]: [
          {
            var: segmentName,
          },
        ],
      };
    } else if (selectedSegment.configuration.type === "bucket") {
      return {
        [getDefaultInputOperatorForSegment(selectedSegment)]: [
          {
            // Note: special case - `bucket` -> `leanplum_id` conversion
            var: "leanplum_id",
          },
          value,
          value,
        ],
      };
    }
    return {
      [getDefaultInputOperatorForSegment(selectedSegment)]: [
        {
          var: segmentName,
        },
        value,
      ],
    };
  };

  return {
    dropdownItems,
    allItems, // Export allItems for testing
    onFilter,
    makeNode,
  };
}
