import camelcaseKeys from "camelcase-keys";

import { TargetSegment, TargetSegmentGuard } from "@/api/segments/typedefs";
import { TargetType } from "@/api/target/typedefs";
import { httpClient, throwIfBadResponse } from "@/lib/http";
import { cast } from "@/lib/typing";

export async function fetchTargetSegments(
  targetType: TargetType,
  productLineId: number
) {
  const path = `/api/targeting-segments/${targetType}/${productLineId}`;
  const response = await httpClient.get(path);

  await throwIfBadResponse(response);

  return convertTargetSegments(await response.json());
}

export function convertTargetSegments(data: unknown): TargetSegment[] {
  if (!Array.isArray(data)) {
    throw new Error("Unexpected data type");
  }
  return data.map((inApp) => convertTargetSegment(inApp));
}

export function convertTargetSegment(data: unknown): TargetSegment {
  if (typeof data !== "object") {
    throw new Error("Unexpected data type");
  }
  const camelCasedData = camelcaseKeys({ ...data });
  const segment = cast(TargetSegmentGuard, camelCasedData);
  if (segment.isRuntime) {
    segment.configuration.label = segment.configuration.label + " (Runtime)";
  }
  return segment;
}
