import { TargetDetail, TargetType } from "@/api/target/typedefs";
import { httpClient, Json, throwIfBadResponse } from "@/lib/http";

export async function updateTarget(
  targetType: TargetType,
  productLineId: number,
  targetId: number,
  target: TargetDetail
): Promise<{
  targetId: string;
}> {
  const response = await httpClient.post(
    `/${targetType}/target-json/${productLineId}/${targetId}`,
    {
      inactive: target.inactive,
      include_premium_users: target.includePremiumUsers,
      target_name: target.targetName,
      target_time_zone: target.targetTimeZone,
      segments: target.segments as Json,
    }
  );

  await throwIfBadResponse(response);

  const responseJson = await response.json();
  const savedId = responseJson["saved_id"];
  if (typeof savedId !== "string") {
    throw new Error("Unexpected response");
  }
  return {
    targetId: responseJson["saved_id"],
  };
}
