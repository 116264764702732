import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dropdown-container" }

import { toRefs } from "vue";

import type { TargetSegment } from "@/api/segments/typedefs";
import { NodeObject } from "@/components/targetingpicker/utils/typedefs";

import BorderedContainer from "./BorderedContainer.vue";
import FilterDropdown from "./FilterDropdown.vue";
import { useSegmentPicker } from "./useSegmentPicker";


export default /*@__PURE__*/_defineComponent({
  __name: 'SegmentPicker',
  props: {
    segments: {},
    disable: { type: Boolean }
  },
  emits: ["add-node"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const { segments } = toRefs(props);

const emit = __emit;

const { dropdownItems, onFilter, makeNode } = useSegmentPicker(segments);

const handleUpdate = (value: string) => {
  emit("add-node", makeNode(value));
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BorderedContainer, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(FilterDropdown, {
          "model-value": "",
          dense: "",
          options: _unref(dropdownItems),
          "on-filter": _unref(onFilter),
          disable: _ctx.disable,
          placeholder: "Add new segment",
          "onUpdate:modelValue": handleUpdate
        }, null, 8, ["options", "on-filter", "disable"])
      ])
    ]),
    _: 1
  }))
}
}

})