import { Ref, ref, watch } from "vue";

import { fetchTarget } from "@/api/target/details";
import { TargetDetail, TargetType } from "@/api/target/typedefs";
import { ReadonlyRef } from "@/lib/typing";

export interface TargetingDataErrorResponse {
  message: string;
}

export interface UseTargetingDataPayload {
  productLineId: ReadonlyRef<number>;
  targetType: ReadonlyRef<TargetType>;
  targetId: ReadonlyRef<number | undefined> | undefined;
  onError: (payload: TargetingDataErrorResponse) => unknown;
  onSuccess?: () => unknown;
}

export function useTargetingData(payload: UseTargetingDataPayload) {
  const isSubmitting = ref(false);
  const isLoading = ref(false);
  const targetValue: Ref<TargetDetail> = ref(buildEmptyTarget());

  function buildEmptyTarget(): TargetDetail {
    return {
      inactive: false,
      includePremiumUsers: false,
      query: "",
      targetId: "",
      targetName: "",
      targetTimeZone: getCurrentTimezoneValue(),
      segments: {},
    };
  }

  function getCurrentTimezoneValue(): string {
    const offsetMinutes = new Date().getTimezoneOffset();
    const offsetHours = -offsetMinutes / 60;
    const roundedOffsetHours = Math.round(offsetHours);
    const clampedOffsetHours = Math.max(-12, Math.min(12, roundedOffsetHours));
    return clampedOffsetHours === 0 ? "utc_0" : `utc_${clampedOffsetHours}`;
  }

  async function fetchData() {
    if (payload.targetId?.value === undefined) {
      return;
    }
    try {
      isLoading.value = true;
      targetValue.value = await fetchTarget(
        payload.targetType.value,
        payload.productLineId.value,
        payload.targetId.value
      );
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    } catch (error: unknown) {
      payload.onError({
        message: `Failed to fetch push message with ID: ${payload.targetId.value}`,
      });
    } finally {
      isLoading.value = false;
    }
  }

  watch(
    [payload.productLineId, payload.targetType, payload.targetId],
    async () => {
      isLoading.value = true;
      await fetchData();
    },
    {
      immediate: false,
    }
  );

  return {
    isSubmitting,
    isLoading,
    targetValue,
    fetchData,
  };
}
