import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "flex items-center q-gutter-sm" }
const _hoisted_3 = {
  key: 0,
  class: "q-mt-md text-negative"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }

import { useAsyncState } from "@vueuse/core";
import useFormChild from 'quasar/src/composables/use-form-child.js';;
import { provide, readonly, toRefs } from "vue";

import { fetchTargetSegments } from "@/api/segments/list";
import { TargetType } from "@/api/target/typedefs";
import InputNode from "@/components/targetingpicker/InputNode.vue";
import {
  dictionaryKey,
  productKey,
  segmentsKey,
} from "@/components/targetingpicker/keys";
import SegmentPicker from "@/components/targetingpicker/SegmentPicker.vue";
import { useTargetingPicker } from "@/components/targetingpicker/useTargetingPicker";
import {
  isLogicOperator,
  isNodeArray,
} from "@/components/targetingpicker/utils/guards";
import { uniqueKey } from "@/components/targetingpicker/utils/key";
import { NodeObject } from "@/components/targetingpicker/utils/typedefs";
import { bindPropToRef } from "@/lib/propToRef";

import Node from "./Node.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'TargetingPicker',
  props: {
    dictionary: {},
    modelValue: {},
    productLineId: {},
    productId: {},
    type: {},
    disable: { type: Boolean },
    readOnly: { type: Boolean },
    dense: { type: Boolean }
  },
  emits: ["update:model-value"],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;

const props = __props;

const { productId, productLineId, type, dictionary, dense } = toRefs(props);

const modelValueRef = bindPropToRef<NodeObject>(
  () => props.modelValue,
  (tree: NodeObject) => {
    emit("update:model-value", tree);
  }
);

const {
  state: segments,
  isLoading,
  isReady,
  error,
} = useAsyncState(
  () => fetchTargetSegments(type.value, productLineId.value),
  []
);

provide(segmentsKey, segments);
provide(productKey, readonly(productId));
provide(dictionaryKey, readonly(dictionary));

const {
  toggleOperator,
  updateInput,
  addNewInput,
  removeInput,
  validate,
  resetValidation,
  errorMessage,
} = useTargetingPicker({
  modelValue: modelValueRef,
});

useFormChild({
  validate: validate,
  resetValidation: resetValidation,
});

return (_ctx: any,_cache: any) => {
  const _component_q_spinner = _resolveComponent("q-spinner")!

  return (_unref(isReady))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass({ 'q-pa-md': !_unref(dense) })
        }, [
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(modelValueRef), (value, key, index) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: _unref(uniqueKey)(value[0])
              }, [
                (_unref(isLogicOperator)(key) && _unref(isNodeArray)(value))
                  ? (_openBlock(), _createBlock(Node, {
                      key: 0,
                      operator: key,
                      "parent-path": "",
                      "node-value": value,
                      disable: _ctx.disable,
                      "read-only": _ctx.readOnly,
                      onToggle: _unref(toggleOperator),
                      onUpdateValue: _unref(updateInput),
                      onRemove: _unref(removeInput)
                    }, null, 8, ["operator", "node-value", "disable", "read-only", "onToggle", "onUpdateValue", "onRemove"]))
                  : (_openBlock(), _createBlock(InputNode, {
                      key: 1,
                      path: key,
                      index: index,
                      "model-value": _ctx.modelValue,
                      disable: _ctx.disable,
                      "read-only": _ctx.readOnly,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = (data) => _unref(updateInput)('', data)),
                      onRemove: _cache[1] || (_cache[1] = ($event: any) => (_unref(removeInput)('')))
                    }, null, 8, ["path", "index", "model-value", "disable", "read-only"]))
              ], 64))
            }), 128)),
            (!_ctx.readOnly)
              ? (_openBlock(), _createBlock(SegmentPicker, {
                  key: 0,
                  segments: _unref(segments),
                  "product-line-id": props.productLineId,
                  type: props.type,
                  disable: _ctx.disable,
                  onAddNode: _unref(addNewInput)
                }, null, 8, ["segments", "product-line-id", "type", "disable", "onAddNode"]))
              : _createCommentVNode("", true)
          ]),
          (_unref(errorMessage))
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_unref(errorMessage)), 1))
            : _createCommentVNode("", true)
        ], 2)
      ]))
    : (_unref(isLoading))
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_q_spinner, {
            size: "50px",
            color: "primary"
          })
        ]))
      : (_unref(error))
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[2] || (_cache[2] = [
            _createElementVNode("span", null, "Could not load target segments.", -1)
          ])))
        : _createCommentVNode("", true)
}
}

})