import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, isRef as _isRef, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"padding":"4px 4px 4px 4px"}
}
const _hoisted_2 = {
  key: 0,
  class: "text-negative error-message"
}

import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';import QVirtualScroll from 'quasar/src/components/virtual-scroll/QVirtualScroll.js';import useFormChild from 'quasar/src/composables/use-form-child.js';;
import { Ref, ref } from "vue";

import { FilterEventPayload } from "@/api/typedefs";
import { Option } from "@/components/targetingpicker/typedefs";

import { useFilterDropdown } from "./useFilterDropdown";


export default /*@__PURE__*/_defineComponent({
  __name: 'FilterDropdown',
  props: {
    modelValue: {},
    options: {},
    textColor: {},
    onFilter: { type: Function },
    disableFilter: { type: Boolean },
    isLoading: { type: Boolean },
    disable: { type: Boolean },
    label: {},
    required: { type: Boolean }
  },
  emits: ["update:modelValue", "virtual-scroll"],
  setup(__props: any, { emit: __emit }) {

const virtualScrollRef: Ref<QVirtualScroll | null> = ref(null);

const props = __props;

const emit = __emit;

const {
  filterText,
  isFilterLoading,
  flattenedItems,
  label,
  onDropdownHide,
  scrollTo,
  validate,
  errorMessage,
  resetValidation,
} = useFilterDropdown(props);

const onItemClick = (value: string): void => {
  resetValidation();
  emit("update:modelValue", value);
};

const onVirtualScroll = (details: {
  index: number;
  from: number;
  to: number;
  direction: "increase" | "decrease";
  ref: QVirtualScroll;
}) => {
  if (details.direction === "decrease" && details.from === 0) {
    return;
  }
  if (
    details.index === 0 &&
    details.from === 0 &&
    details.direction === "increase"
  ) {
    virtualScrollRef.value?.scrollTo(scrollTo.value);
  }
  emit("virtual-scroll", details);
};

useFormChild({
  validate: validate,
  resetValidation: resetValidation,
});

return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_unref(QBtnDropdown), {
      flat: "",
      "no-caps": "",
      rounded: "",
      "text-color": _ctx.textColor,
      size: "small",
      padding: "sm",
      "dropdown-icon": "expand_more",
      label: _unref(label),
      "is-loading": _ctx.isLoading,
      disable: _ctx.disable,
      class: _normalizeClass({
        'has-errors': !!_unref(errorMessage),
      }),
      onHide: _unref(onDropdownHide)
    }, {
      default: _withCtx(() => [
        (_ctx.onFilter !== undefined && !_ctx.disableFilter)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_q_input, {
                modelValue: _unref(filterText),
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(filterText) ? (filterText).value = $event : null)),
                dense: "",
                flat: "",
                outlined: "",
                solo: "",
                "hide-bottom-space": "",
                placeholder: "Search...",
                autofocus: ""
              }, {
                append: _withCtx(() => [
                  _createVNode(_component_q_icon, {
                    name: _unref(isFilterLoading) ? 'sync' : 'search',
                    class: _normalizeClass({ 'rotate-animation': _unref(isFilterLoading) })
                  }, null, 8, ["name", "class"])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          _createVNode(_unref(QVirtualScroll), {
            ref_key: "virtualScrollRef",
            ref: virtualScrollRef,
            items: _unref(flattenedItems),
            style: {"max-height":"200px"},
            onVirtualScroll: onVirtualScroll
          }, {
            default: _withCtx(({ item }) => [
              (item.type === 'item')
                ? _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                    key: item.name,
                    dense: "",
                    clickable: "",
                    active: item.value === _ctx.modelValue,
                    onClick: ($event: any) => (onItemClick(item.value))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_section, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_item_label, {
                            class: _normalizeClass({ 'child-label': item.isChild })
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.label), 1)
                            ]),
                            _: 2
                          }, 1032, ["class"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["active", "onClick"])), [
                    [_directive_close_popup]
                  ])
                : (item.type === 'group')
                  ? (_openBlock(), _createBlock(_component_q_item, {
                      key: item.label,
                      dense: "",
                      class: "text-weight-bold"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_item_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.label), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["items"]),
          (_unref(flattenedItems).length === 0)
            ? (_openBlock(), _createBlock(_component_q_item, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_section, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_label, null, {
                        default: _withCtx(() => _cache[1] || (_cache[1] = [
                          _createTextVNode("No results found")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["text-color", "label", "is-loading", "disable", "class", "onHide"]),
    (_unref(errorMessage))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_unref(errorMessage)), 1))
      : _createCommentVNode("", true)
  ]))
}
}

})