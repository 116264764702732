import { isEqual } from "lodash";
import { Ref, ref, watch } from "vue";

export function bindPropToRef<T extends Record<string, unknown>>(
  prop: T | (() => T),
  onRefUpdate: ((newValue: T) => void) | null = null
): Ref<T> {
  const propGetter: () => T = typeof prop === "function" ? prop : () => prop;
  // Type cast needed due to https://github.com/vuejs/composition-api/issues/483
  const writableRef = ref({ ...propGetter() }) as Ref<T>;
  watch(
    propGetter,
    () => {
      writableRef.value = { ...propGetter() };
    },
    { deep: true }
  );
  watch(
    writableRef,
    () => {
      if (onRefUpdate !== null) {
        // This condition is necessary to avoid infinite callback loop
        if (!isEqual(writableRef.value, propGetter())) {
          onRefUpdate(writableRef.value);
        }
      }
    },
    { deep: true }
  );
  return writableRef;
}
