import { cloneDeep, get, set } from "lodash";
import { ref, Ref } from "vue";

import {
  isNodeArray,
  isNodeObject,
} from "@/components/targetingpicker/utils/guards";
import {
  getOperatorFromPath,
  getParentPath,
  getPathIndex,
  trimPathIndex,
} from "@/components/targetingpicker/utils/path";
import {
  getOperator,
  moveDown,
  moveUp,
} from "@/components/targetingpicker/utils/tree";
import {
  isEmptyTree,
  wrapTree,
  wrapTreePath,
} from "@/components/targetingpicker/utils/tree";
import {
  NodeObject,
  ToggleOptions,
} from "@/components/targetingpicker/utils/typedefs";

export interface UseTargetingPickerPayload {
  modelValue: Ref<NodeObject>;
}

export function useTargetingPicker(payload: UseTargetingPickerPayload) {
  const errorMessage: Ref<null | string> = ref(null);

  const changeToAndOperator = (path: string, index: number) => {
    const parentPath = getParentPath(path);
    let tree = cloneDeep(payload.modelValue.value);
    let fromPath = path;

    if (getOperatorFromPath(parentPath) !== "and") {
      tree = wrapTree(tree, "and");
      fromPath = wrapTreePath(fromPath, "and");
    }

    tree = moveUp(tree, fromPath, index);
    payload.modelValue.value = tree;
  };

  const changeToOrOperator = (path: string, index: number) => {
    let tree = cloneDeep(payload.modelValue.value);

    if (getOperatorFromPath(path) === "or") {
      throw new Error("Already in `or` operator");
    }

    tree = moveDown(tree, path, "or", index);
    payload.modelValue.value = tree;
  };

  const toggleOperator = (options: ToggleOptions) => {
    const operator = getOperatorFromPath(options.path);
    if (operator === "or") {
      changeToAndOperator(options.path, options.btnIndex);
    } else if (operator === "and") {
      changeToOrOperator(options.path, options.btnIndex);
    } else {
      throw new Error("Unsupported operation");
    }
  };

  const updateInput = (path: string, value: NodeObject) => {
    // Lodash doesn't understand root path
    if (path !== "") {
      payload.modelValue.value = set(payload.modelValue.value, path, value);
    } else {
      payload.modelValue.value = value;
    }
  };

  const addNewInput = (value: NodeObject) => {
    let tree = cloneDeep(payload.modelValue.value);
    if (!isNodeObject(tree)) {
      throw new Error("Unexpected type");
    }

    if (isEmptyTree(payload.modelValue.value)) {
      resetValidation();
      payload.modelValue.value = value;
      return;
    }

    const operator = getOperator(payload.modelValue.value);
    if (operator !== "and") {
      tree = wrapTree(tree, "and");
    }

    if (!("and" in tree) || !isNodeArray(tree["and"])) {
      throw new Error("Missing `and` property or it has incorrect type");
    }

    tree["and"].push(value);

    payload.modelValue.value = tree;
    resetValidation();
  };

  const removeInput = (path: string) => {
    if (path === "") {
      payload.modelValue.value = {};
      return;
    }
    let tree = cloneDeep(payload.modelValue.value);
    const index = getPathIndex(path);
    const parentPath = trimPathIndex(path);
    const parentValue = get(tree, parentPath);
    if (!isNodeArray(parentValue)) {
      throw new Error("Unexpected type");
    }

    parentValue.splice(index, 1);

    // If there's only one element left, let's unwrap it.
    if (parentValue.length === 1) {
      tree = moveUp(tree, parentPath, 0);
    }

    payload.modelValue.value = tree;
  };

  const validate = () => {
    if (isEmptyTree(payload.modelValue.value)) {
      errorMessage.value = "Please add at least one segment.";
      return false;
    }
    return true;
  };

  const resetValidation = () => {
    errorMessage.value = null;
  };

  return {
    toggleOperator,
    updateInput,
    addNewInput,
    removeInput,
    errorMessage,
    validate,
    resetValidation,
  };
}
