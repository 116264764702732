import { InputOperatorType } from "@/api/segments/typedefs";
import { FlatOptions } from "@/components/targetingpicker/typedefs";

export const InputOperatorOptions: { [k in InputOperatorType]: FlatOptions } = {
  boolean_type: {
    true: "True",
    false: "False",
    undefined: "Undefined",
    false_or_undefined: "False or Undefined",
    true_or_undefined: "True or Undefined",
    defined: "Defined",
  },

  tag_boolean_type: {
    tag_true: "True",
    tag_false: "False",
    tag_undefined: "Undefined",
    tag_false_or_undefined: "False or Undefined",
    tag_true_or_undefined: "True or Undefined",
    tag_defined: "Defined",
  },

  date_type: {
    is: "is",
    is_not: "all except",
  },

  numeric_type: {
    is: "is",
    is_not: "is not",
    greater: "is greater than",
    greater_or_equal: "is greater than or equal to",
    less: "is less than",
    less_or_equal: "is less than or equal to",
  },

  list_type: {
    is: "is",
    is_not: "is not",
  },

  list_type_repeated: {
    is_in: "is in",
    is_not_in: "is not in",
  },

  text_type: {
    is: "is",
    is_not: "is not",
    contains: "contains",
    not_contains: "doesn't contain",
    wildcard_regexp: "wildcard/regexp match",
    not_wildcard_regexp: "doesn't match wildcard/regexp",
  },

  operator_type: {
    and: "AND",
    or: "OR",
  },

  temporal_type: {
    or_less_days_ago: "or less days ago",
    or_more_days_ago: "or more days ago",
    days_ago: "days ago",
    never: "never",
  },

  per_frequency: {
    ever: "time(s) ever",
    per_session: "time(s) per_session",
    per_second: "time(s) per second",
    per_minute: "time(s) per minute",
    per_hour: "time(s) per hour",
    per_day: "time(s) per day",
    per_week: "time(s) per week",
    per_month: "time(s) per month",
  },
};
