import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bordered-container"
}
const _hoisted_2 = { key: 1 }


export default /*@__PURE__*/_defineComponent({
  __name: 'BorderedContainer',
  props: {
    hideBorder: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (!props.hideBorder)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default")
      ]))
}
}

})